//import InputRangeSlider from "./calculator-form-fields/InputRangeSlider";
import Card from "./ui/Card";
import Slider, { SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import { useState } from "react";
import NumberFormat from "react-number-format";

function Calculator() {
	const [state, setState] = useState({
		inputAankoopprijs: "",
		sliderJaren: 2,
		sliderMeerwaarde: 1,
		sliderHuuropbrengst: 500,
		sliderAantalWekenVerhuurd: 1,
	});

	function changeAankoopprijsHandler(objValues) {
		console.log(objValues);
		const value = objValues.value;
		setState({
			...state,
			inputAankoopprijs: value,
		});
	}

	function changeJarenHandler(evt) {
		setState({
			...state,
			sliderJaren: evt,
		});
	}
	function changeMeerwaardeHandler(evt) {
		setState({
			...state,
			sliderMeerwaarde: evt,
		});
	}

	function changeHuuropbrengstHandler(evt) {
		setState({
			...state,
			sliderHuuropbrengst: evt,
		});
	}

	function changeAantalWekenVerhuurdHandler(evt) {
		setState({
			...state,
			sliderAantalWekenVerhuurd: evt,
		});
	}

	const { Handle } = Slider;

	const handleSliderJaren = (props) => {
		const { value, dragging, index, ...restProps } = props;
		return (
			<SliderTooltip
				prefixCls="rc-slider-tooltip"
				overlay={`${value} jaar`}
				visible={true}
				placement="top"
				key={index}
			>
				<Handle value={value} {...restProps} />
			</SliderTooltip>
		);
	};

	const handleSliderMeerwaarde = (props) => {
		const { value, dragging, index, ...restProps } = props;
		return (
			<SliderTooltip
				prefixCls="rc-slider-tooltip"
				overlay={`${value}%`}
				visible={true}
				placement="top"
				key={index}
			>
				<Handle value={value} {...restProps} />
			</SliderTooltip>
		);
	};

	const handleSliderHuuropbrengst = (props) => {
		const { value, dragging, index, ...restProps } = props;
		return (
			<SliderTooltip
				prefixCls="rc-slider-tooltip"
				overlay={`€${value}/maand`}
				visible={true}
				placement="top"
				key={index}
			>
				<Handle value={value} {...restProps} />
			</SliderTooltip>
		);
	};

	const handleAantalWekenVerhuurd = (props) => {
		const { value, dragging, index, ...restProps } = props;
		return (
			<SliderTooltip
				prefixCls="rc-slider-tooltip"
				overlay={`${value === 1 ? value + " week" : value + " weken"}`}
				visible={true}
				placement="top"
				key={index}
			>
				<Handle value={value} {...restProps} />
			</SliderTooltip>
		);
	};

	function onSubmitForm(e) {
		e.preventDefault();
		const focusable = document.querySelectorAll('[tabindex="0"');
		focusable[0].focus();
	}

	function calculateResult() {
		let resultUnformatted =
			state.inputAankoopprijs *
			state.sliderJaren *
			state.sliderMeerwaarde *
			state.sliderHuuropbrengst *
			state.sliderAantalWekenVerhuurd;
		const resultFormatter = new Intl.NumberFormat("be-BE", {
			style: "currency",
			currency: "EUR",
		});
		return resultFormatter.format(resultUnformatted);
	}

	return (
		<form onSubmit={onSubmitForm}>
			<Card>
				<div className="inputContainer">
					<div className="inputElement number">
						<NumberFormat
							displayType={"input"}
							thousandSeparator={"."}
							prefix={"€ "}
							type="text"
							placeholder="Aankoopprijs"
							name="inputAankoopprijs"
							decimalSeparator={","}
							onValueChange={changeAankoopprijsHandler}
							decimalScale={2}
							fixedDecimalScale={true}
						/>
					</div>
				</div>
			</Card>
			<Card>
				<div className="inputContainer inputSlider">
					<div className="label">Tijd (in jaren)</div>
					<div className="inputElement slider">
						<Slider
							min={0}
							max={30}
							defaultValue={state.sliderJaren}
							height="30px"
							trackStyle={{ backgroundColor: "var(--mainColor)", height: 20 }}
							handleStyle={{
								height: 30,
								width: 30,
								backgroundColor: "white",
								borderColor: "var(--mainTextColor)",
								borderWidth: "6px",
							}}
							railStyle={{
								height: 20,
							}}
							onChange={changeJarenHandler}
							handle={handleSliderJaren}
						/>
					</div>
					<div className="labelElement slider">
						<label>0 - 30 jaar</label>
					</div>
				</div>
				<div className="inputContainer inputSlider">
					<div className="label">Meerwaarde</div>
					<div className="inputElement slider">
						<Slider
							min={0}
							max={30}
							defaultValue={state.sliderMeerwaarde}
							height="30px"
							trackStyle={{ backgroundColor: "var(--mainColor)", height: 20 }}
							handleStyle={{
								height: 30,
								width: 30,
								backgroundColor: "white",
								borderColor: "var(--mainTextColor)",
								borderWidth: "6px",
							}}
							railStyle={{
								height: 20,
							}}
							onChange={changeMeerwaardeHandler}
							handle={handleSliderMeerwaarde}
						/>
					</div>
					<div className="labelElement slider">
						<label>0 - 15% per jaar</label>
					</div>
				</div>
				<div className="inputContainer inputSlider">
					<div className="label">Huuropbrengst per week</div>
					<div className="inputElement slider">
						<Slider
							min={0}
							max={2000}
							defaultValue={state.sliderHuuropbrengst}
							height="30px"
							step={25}
							trackStyle={{ backgroundColor: "var(--mainColor)", height: 20 }}
							handleStyle={{
								height: 30,
								width: 30,
								backgroundColor: "white",
								borderColor: "var(--mainTextColor)",
								borderWidth: "6px",
							}}
							railStyle={{
								height: 20,
							}}
							onChange={changeHuuropbrengstHandler}
							handle={handleSliderHuuropbrengst}
						/>
					</div>
					<div className="labelElement slider">
						<label>€0 - €2000 per maand</label>
					</div>
				</div>
				<div className="inputContainer inputSlider">
					<div className="label">Aantal weken verhuurd</div>
					<div className="inputElement slider">
						<Slider
							min={0}
							max={52}
							defaultValue={state.sliderAantalWekenVerhuurd}
							height="30px"
							trackStyle={{ backgroundColor: "var(--mainColor)", height: 20 }}
							handleStyle={{
								height: 30,
								width: 30,
								backgroundColor: "white",
								borderColor: "var(--mainTextColor)",
								borderWidth: "6px",
							}}
							railStyle={{
								height: 20,
							}}
							onChange={changeAantalWekenVerhuurdHandler}
							handle={handleAantalWekenVerhuurd}
						/>
					</div>
					<div className="labelElement slider">
						<label>0 - 52 weken</label>
					</div>
				</div>
			</Card>
			<Card className="result">
				<div>{calculateResult()}</div>
			</Card>
		</form>
	);
}

export default Calculator;
