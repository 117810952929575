//import logo from './logo.svg';
//import './App.css';

import Calculator from "./components/Calculator";

function App() {
	return (
		<main className="App">
			<Calculator />
		</main>
	);
}

export default App;
